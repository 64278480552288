<template>
  <div
    class="pa-0"
    style="background-color: transparent; width: 100%; height: 70vh"
  >
    <div style="width: 100%; height: 70vh" class="overflow-y-auto">
      <v-card color="#fff" flat class="mb-4 mx-4">
        <v-img
          v-if="type == 'img'"
          :src="src"
          width="100%"
          max-width="100%"
          max-height="100%"
        ></v-img>

        <pdf v-else :src="src" ref="pdfViewer" @num-pages="handleNumPages" />
        <div v-if="numPages > 0">
          <pdf
            v-for="i in numPages - 1"
            :key="i + 1"
            :src="src"
            :page="i + 1"
          ></pdf>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf';
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    }
  },
  components: { pdf },
  data: () => ({
    numPages: 0
  }),
  methods: {
    handleNumPages(numPages) {
      this.numPages = numPages;
    }
  }
};
</script>

<style scoped>
.overflow {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.v-toolbar-title {
  align-self: center;
}

.scrollCol {
  overflow: auto;
}
</style>
